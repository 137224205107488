@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,400&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.app-nav-container {
  @apply max-w-7xl mx-auto p-4 sm:p-6;
}
.app-main-container {
  @apply max-w-7xl mx-auto px-4 py-4 sm:py-12;
}
.app-small-main-container {
  @apply max-w-3xl mx-auto px-4 py-4 sm:py-12;
}
.app-modal-width {
  width: min(90vw, 400px);
  max-height: 90vh;
}
.modal-open {
  overflow: hidden;
}
.app-kitchen-container {
  @apply mx-auto py-4 sm:py-12;
  padding-left: 5%;
  padding-right: 5%;
}
.print-bill-main-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.app-print-bill-container {
  width: 300px;
  /* padding: 2px 2px 2px 2px; */
  /* background-color: grey; */
}
.img-size {
  width: 80px;
}
